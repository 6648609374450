import * as React from "react";
import SearchSuggestionBox from "../../search-suggestion-box";
import { Icon } from "@blueprintjs/core";
import GenomeTile from "../../genome-tile";
import { performSearch } from "../../../utils/search";

class ATCCHomePage extends React.PureComponent {
  renderGenomeTiles = () => {
    const genomes = [
      {
        name: "Cellvibrio vulgaris (ATCC® 12209™)",
        metadataText: "Added 10/30/2024",
        uuid: "ad26a789e7ca4289",
        iconName: "tom",
      },
      {
        name: "Gallicola barnesae (ATCC® 49795™)",
        metadataText: "Added 12/13/2024",
        uuid: "12e905a78767463b",
        iconName: "jerry",
      },
      {
        name: "Pluralibacter pyrinus (ATCC® 49851™)",
        metadataText: "Added 10/30/2024",
        uuid: "7fda9db083c44565",
        iconName: "spike",
      },
    ];

    return genomes.map((g, idx) => {
      return (
        <GenomeTile
          name={g.name}
          metadataText={g.metadataText}
          idx={idx}
          key={g.uuid}
          uuid={g.uuid}
          iconName={g.iconName}
        />
      );
    });
  };

  render() {
    return (
      <div className="atcc-homepage">
        <div className="welcome">
          <h1>Welcome to the ATCC Genome Portal</h1>
          <p>The only authenticated reference genome database for ATCC microbes</p>
          <a className="view-genomes" href="/genomes">
            {"VIEW ALL GENOMES  "}
            <Icon icon="chevron-right" />
          </a>

          <h2>Search for a genome</h2>
          <SearchSuggestionBox performSearch={performSearch} />

          <div className="recently-updated">
            <h2>Recently published</h2>
            <div className="genome-tile-container">{this.renderGenomeTiles()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ATCCHomePage;
